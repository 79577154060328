
import { defineComponent, computed, PropType, CSSProperties } from 'vue';
import { PayType } from '../typing';

export interface ReceiverAccountType {
  type: PayType | string;
  number: string | number;
}

export default defineComponent({
  props: {
    // define `modelValue` use `v-model` no arguments
    // if you use `v-model:value` please change `modelValue` to `value`
    modelValue: {
      type: Object as PropType<ReceiverAccountType>,
      default: () => {
        return {
          type: 'alipay',
          number: undefined,
        };
      },
    },
    selectStyle: {
      type: Object as PropType<CSSProperties>,
      default: () => {
        return {
          width: '130px',
        };
      },
    },
    inputStyle: {
      type: Object as PropType<CSSProperties>,
      default: () => {
        return {
          width: 'calc(100% - 130px)',
        };
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      },
    });

    return {
      value,
    };
  },
});
